<template>
  <div v-if="isVisible" :style="cssProps">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["roles", "permissions", "cssProps"],

  data() {
    return {
      isVisible: false,
    };
  },

  created() {
    this.authorize();
  },

  updated() {
    this.authorize();
  },

  methods: {
    authorize() {
      if (this.$store.state.userPermissions.some((r) => this.permissions.includes(r))) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },
  },
};
</script>
